/* reset css to overwrite default element style in monolith
 Will be scoped in hypernovaWrapperClassName -> ._hyp class by postcss build
*/
a:hover {
    text-decoration: inherit;
}

/*reset for global style in https://cdn8.rakqa.fr/rakuten-static-deliver/common-superboutique/3.0.3/0/fr/fixed_css/css/pmcsmain.css */
p, ul, ol , address, code, code, pre, dl, q, img,
h1, h2, h3, h4, h5, h6,
table, form, textarea, legend {
    margin-bottom: 0;
}
